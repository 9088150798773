






















































// Vue
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

// Components
import Modal        from '@/components/modals/Default.vue'
import UserImage    from '@/components/user/UserImage.vue'
import ActionBar    from '@/components/widgets/ActionBar.vue'
import TableWrapper from '@/components/table/Wrapper.vue'
import TableHeading from '@/components/table/Heading.vue'
import TableRow     from '@/components/table/Row.vue'

import { ProjectRoleResource } from '@/models/projects/ProjectRoleResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'

@Component({
  components: {
    Modal,
    UserImage,
    ActionBar,
    TableWrapper,
    TableHeading,
    TableRow
  }
})
export default class ReviewMemberChanges extends Vue {

  @Prop()
  private open!: boolean

  @Prop()
  private changedMembers!: Array<ProjectUserResource & { originalRole: ProjectRoleResource | null }>

  // hold the selected users (initially all)
  private selectedUsers: number[] = []

  @Watch('open')
  private onOpenChange(open: boolean): void {
    if (open) {
      this.selectedUsers = this.changedMembers.map((m) => m.id)
    }
  }

  /**+
   *  Reset this user to the original state.
   */
  private resetHandler(user: ProjectUserResource): void {

    // this.selectedUsers
    const idx = this.selectedUsers.findIndex((c) => c === user.id)
    if (idx > -1) {
      this.selectedUsers.splice(idx, 1)
    } else {
      this.selectedUsers.push(user.id)
    }
  }

  private submit(): void {
    this.$emit('submit', this.selectedUsers)
  }
}
