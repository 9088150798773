



























import { Component, Vue, Prop } from 'vue-property-decorator'
import * as Helpers from '@/helpers'

@Component
export default class ItemFilter extends Vue {

  @Prop() private currentFilter!: string | null
  @Prop() private filters!: Array<{ key: string | null; count: number }>
  @Prop() private itemCount!: number

  private get hasActiveFilter(): boolean {

    return this.currentFilter !== null && this.currentFilter !== 'all'
  }

}
