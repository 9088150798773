export interface SelectedUser {
  user_id: number,
  role: string | undefined
}

export class ProjectUsersCreateRequest {
  public users: SelectedUser[] = []

  constructor(users?: SelectedUser[]) {
    this.users = users ?? []
  }

}
