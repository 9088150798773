











































































import { userModule, stateModule, projectModule } from '@/store'
import { Route }                                  from 'vue-router'
import { Component, Prop, Vue }                   from 'vue-property-decorator'

import { ProjectService } from '@/services/project'
import { OrganisationService } from '@/services/organisation'

// Components
import DropdownInput from '@/components/inputs/Dropdown.vue'
import Modal from '@/components/modals/Default.vue'
import SearchInput from '@/components/inputs/Search.vue'
import Checkbox from '@/components/inputs/Checkbox.vue'
import Tabs from '@/components/widgets/Tabs.vue'
import UserImage from '@/components/user/UserImage.vue'
import ActionBar from '@/components/widgets/ActionBar.vue'
import Pagination from '@/components/paginations/Pagination.vue'
import AddMembers from '@/views/dashboard/projects/detail/index/AddMembers.vue'
import ReviewMemberChanges from '@/views/dashboard/projects/detail/index/ReviewMemberChanges.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import { ProjectUserResource } from '@/models/projects/ProjectUserResource'
import { ProjectRoleResource } from '@/models/projects/ProjectRoleResource'
import { ProjectResource } from '@/models/projects/ProjectResource'
import { AuthUserResource } from '@/models/users/AuthUserResource'

import { ProjectUserIndexRequest } from '@/requests/projects/ProjectUserIndexRequest'

const organisationService: OrganisationService = new OrganisationService()

@Component({
  components: {
    Tabs,
    Modal,
    Checkbox,
    UserImage,
    ActionBar,
    Pagination,
    AddMembers,
    SmallLoader,
    SearchInput,
    DropdownInput,
    ReviewMemberChanges
  }
})
export default class ProjectTeam extends Vue {

  @Prop()
  private readonly project!: ProjectResource

  private projectService!: ProjectService

  private loading: boolean = false
  private showAddMemberModal: boolean = false
  private roles: ProjectRoleResource[] = []
  private users: IndexResponse<ProjectUserResource> | null = null
  private totalUsers: number = 0
  private params: ProjectUserIndexRequest = {
    roles: '',
    search: '',
    page: '0',
    pagination: 'false',
    order_by: 'asc',
    sort_by: 'users.last_name'
  }

  private get filters(): ProjectRoleResource[] {
    if (this.roles) {
      return [{ id: 0, name: 'All', slug: '', count: null }, ...this.roles.map((role) => {
        return { ...role, count: null }
      })].filter((role) => {
        if (role.slug !== 'library-viewer' && role.slug !== 'library-owner' && role.slug !== 'admin' && role.slug !== 'implementation-creator' && role.slug !== 'implementation-officer') {
          return true
        }
      })
    }
    return []
  }

  // the logged on user
  private get user(): AuthUserResource | undefined {
    return userModule.user
  }

  private get canEditTeam(): boolean {
    return this.project.canPerformAction('can_edit_team')
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any): Promise<void> {
    const projectService: ProjectService = new ProjectService({ project_id: parseInt(to.params.project_id, 10) })
    stateModule.setLoading(true)
    try {
      const query = to.query as ProjectUserIndexRequest
      query.pagination = 'false'
      query.sort_by = 'last_name'
      const [users, { data }] = await Promise.all([projectService.getUsers(query), organisationService.getRoles()])

      next((vm: ProjectTeam) => {
        vm.projectService = projectService
        vm.users = users
        vm.totalUsers = users.data.length
        vm.roles = data
        vm.params.roles = query.roles || ''
        vm.params.search = query.search || ''
      })
    } catch {
      next(from.path)
    } finally {
      stateModule.setLoading(false)
    }
  }

  private roleOptions(roles: string[]): SelectItem[] {
    // rs is role string
    // ro is role object
    return roles.map((rs) => {
      const role = this.roles.find((ro) => ro.slug === rs)
      return { value: role?.slug || '', label: role?.name || '' }
    })
  }

  private async getUsers(params: ProjectUserIndexRequest): Promise<void> {
    this.loading = true
    try {
      this.users = await this.projectService.getUsers(params || this.params)
      projectModule.getUsers()
      this.$router.replace({ query: { ...params } })
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }

  private async setFilter(role?: string): Promise<void> {
    this.params.roles = role
    await this.getUsers(this.params)
  }


  private async patchMember(role: string, user: ProjectUserResource): Promise<void> {
    this.loading = true
    try {
      await this.projectService.patchUser(user.id, { role })
      this.getUsers(this.params)
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }

  private async deleteMember(user: ProjectUserResource): Promise<void> {
    if (confirm(`Are you sure you want to delete ${user.name}`)) {
      this.loading = true
      try {
        await this.projectService.deleteUser(user.id)
        await this.getUsers(this.params)
        this.totalUsers = this.users && this.users.data ? this.users.data.length : this.totalUsers
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }

  private async openModal(): Promise<void> {
    this.showAddMemberModal = true
  }

  private closeModal(): void {
    this.showAddMemberModal = false
  }

  private async modalSubmitted(): Promise<void> {
    this.closeModal()
    await this.getUsers(this.params)
    this.totalUsers = this.users && this.users.data ? this.users.data.length : this.totalUsers
  }
}
