




























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import DropdownInput from '@/components/inputs/Dropdown.vue'
import SearchInput   from '@/components/inputs/Search.vue'
import SmallLoader   from '@/components/loaders/SmallLoader.vue'
import Modal         from '@/components/modals/Default.vue'
import UserImage     from '@/components/user/UserImage.vue'
import ActionBar     from '@/components/widgets/ActionBar.vue'
import ItemFilter    from '@/components/widgets/ItemFilter.vue'
import Tabs          from '@/components/widgets/Tabs.vue'
import NoItems       from '@/components/partials/NoItems.vue'

import { ProjectResource }     from '@/models/projects/ProjectResource'
import { ProjectRoleResource } from '@/models/projects/ProjectRoleResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'

import { SelectedUser, ProjectUsersCreateRequest } from '@/requests/projects/ProjectUsersCreateRequest'
import { ProjectUserIndexRequest }  from '@/requests/projects/ProjectUserIndexRequest'
import { projectModule } from '@/store'

@Component({
  components: {
    NoItems,
    Modal,
    DropdownInput,
    SearchInput,
    Tabs,
    ItemFilter,
    UserImage,
    ActionBar,
    SmallLoader
  }
})
export default class AddMembers extends Vue {

  @Prop()
  private readonly project!: ProjectResource

  @Prop()
  private open!: boolean

  @Prop()
  private roles!: ProjectRoleResource[]

  private users: ProjectUserResource[] | null = null
  private loading: boolean = false
  private params: ProjectUserIndexRequest = {
    role: '',
    search: '',
    pagination: 'false'
  }

  private attachUsersForm: ProjectUsersCreateRequest = new ProjectUsersCreateRequest()

  @Watch('open')
  private ifOpened(): void {
    if (this.open) {
      this.getUsers(this.params)
    }
  }

  private get selectedUsersCount(): number {
    return this.attachUsersForm.users.length
  }

  private get canSubmit(): boolean {
    if (this.selectedUsersCount > 0) {
      const selectedUsersWithRoleCount = this.attachUsersForm.users.filter((user) => !!user.role).length
      return this.selectedUsersCount === selectedUsersWithRoleCount
    }
    return false
  }

  private created(): void {
    if (this.project.canPerformAction('can_edit_team')) {
      this.getUsers(this.params)
    }
  }

  private roleOptions(roles: string[]): SelectItem[] {
    // rs is role string
    // ro is role object
    return roles.map((rs) => {
      const role = this.roles.find((ro) => ro.slug === rs)
      return { value: role?.slug || '', label: role?.name || '' }
    })
  }

  private search(): void {
    if (this.project.canPerformAction('can_edit_team')) {
      this.getUsers(this.params)
    }
  }

  private getValue(user: ProjectUserResource): string | undefined {
    return this.attachUsersForm.users.find((u: SelectedUser) => u.user_id === user.id)?.role ?? undefined
  }

  private hasRoleIndicatorClass(user: ProjectUserResource): { isChecked: boolean, hasRoleSelected: boolean } {
    const userClone = this.attachUsersForm.users.find(({ user_id }) => user_id === user.id)
    return {
      isChecked: !!userClone,
      hasRoleSelected: !!userClone?.user_id
    }
  }

  private async submitHandler(): Promise<void> {
    this.loading = true
    try {
      await this.project.attachUsers(this.attachUsersForm)
      this.$emit('submitted')
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
      this.attachUsersForm.users = []
      this.params.search = ''
      this.getUsers(this.params)
      projectModule.getUsers()
    }

  }

  private checkboxInputHandler(user: ProjectUserResource): void {
    const index = this.attachUsersForm.users.findIndex(({ user_id }) => user_id === user.id)
    if (index > -1) {
      this.attachUsersForm.users.splice(index, 1)
    } else {
      this.attachUsersForm.users.push({ user_id: user.id, role: undefined })
    }
  }

  private selectRoleInputHandler(role: string, user: ProjectUserResource): void {
    const index = this.attachUsersForm.users.findIndex(({ user_id }) => user_id === user.id)
    if (index > -1) {
      this.attachUsersForm.users[index].role = role
    }
  }

  private async getUsers(params: ProjectUserIndexRequest): Promise<void> {
    this.loading = true
    try {
      const { data } = await this.project.getAvailableUsers(params)
      this.$set(this, 'users', data)
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }
}
